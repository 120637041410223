import { ServiceFactory } from '@/services/ServiceFactory'
const _barsService = ServiceFactory.get('BarsService')
export default {
  data () {
    return {
      loading: false,
      dialog: false,
      imgBanner: {
        image: ''
      },
      image: {
        width: '',
        height: ''
      },
      showError: false,
      imageBanner: null
    }
  },
  props: {
    itemData: Object,
    action: String,
    zbBarId: String
    // modal: Boolean
  },
  watch: {
    itemData: {
      handler: 'setItem',
      immediate: true
    },
    action: {
      handler: 'initAction',
      immediate: true
    },
    zbBarId: {
      handler: 'initAction',
      immediate: true
    }
  },
  methods: {
    async initAction () {
      console.log(this.action)
      console.log(this.zbBarId)
      // if (this.modal) {
      //   this.dialog = true
      // }
    },
    async setItem () {
      if (this.itemData) {
        this.imgBanner.image = this.itemData.image
      }
      // console.log(this.action)
      // try {
      //   this.companies = await _barsService.listBar()
      // } catch (error) {
      //   console.log(error)
      // }
    },
    closeModal () {
      this.imgBanner = {
        image: ''
      }
      this.image = {
        width: '',
        height: ''
      }
      console.log('closeeeeee')
      this.loading = false
      this.dialog = false
      this.$emit('closeThisModal')
    },
    deleteImg () {
      this.loading = true
      this.$swal({
        title: '¿Está seguro de eliminar la imágen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(this.zbBarId, this.imgBanner.image)
            // const pd = {
            //   image: this.imgBanner.image
            // }
            await _barsService.delCarImg(this.zbBarId, this.imgBanner)
            this.$swal(
              'Imágen eliminada',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              // this.initialize()
              this.loading = false
              this.$emit('closeThisModal')
            })
          } catch (error) {
            this.loading = false
            console.log(error)
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: 'La imágen no se pudo eliminar.'
            })
          }
        }
      })
    },
    async submit () {
      this.loading = true
      console.log(this.itemData)
      console.log(this.imageBanner)
      if (this.action === 'create') {
        if (this.imageBanner && this.imageBanner[0]) {
          try {
            const newImage = new FormData()
            newImage.append('carousel', this.imageBanner[0])
            await _barsService.postImage(this.zbBarId, newImage)
            this.$notify({
              type: 'success',
              text: 'Imágen agredada correctamente'
            })
            this.loading = false
            this.$emit('closeThisModal')
            // this.$emit('closeModal')
          } catch (error) {
            console.log(error)
            this.loading = false
            this.$notify({
              type: 'error',
              text: 'Ocurrió un error al guardar la imágen'
            })
          }
        } else {
          console.log('no image')
        }
      }
    },
    handleImgBannerUpload (e) {
      this.imageBanner = e.target.files
      this.previewImgBanner(e)
      console.log(this.image)
      // if (this.image.height === 924 && this.image.width === 1640) {
      //   this.enable = true
      //   this.showError = false
      //   console.log('permitido')
      // } else {
      //   console.log('no permitido')
      //   this.enable = false
      //   this.showError = false
      // }
    },
    previewImgBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          img.onload = () => {
            this.image.width = img.width
            this.image.height = img.height
          }
          img.src = e.target.result
          this.imgBanner.image = e.target.result
        }
      }
    }
  }
}
