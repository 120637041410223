import { mapActions } from 'vuex'
import { ServiceFactory } from '@/services/ServiceFactory'
import ZbImageModal from './image-modal'
const _barsService = ServiceFactory.get('BarsService')
export default {
  name: 'ZbTabImages',
  components: {
    ZbImageModal
  },
  data: () => ({
    barData: {
      bar: {
        images: {
          profile: null,
          banner: null,
          name: null,
          slogan: null
        }
      }
    },
    imageProfile: null,
    imageBanner: null,
    loading: false,
    preview: {
      profile: null,
      banner: null
    },
    zbBarId: '',
    cards: [
      { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 },
      { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
      { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 }
    ],
    currentItem: null,
    submitAction: 'create', // String 'create' || 'edit'
    newImgMod: false,
    dialog: false,
    carouselImg: []
  }),
  mounted () {
    // this.$refs.calendar.scrollToTime('08:00')
    this.initialize()
  },
  methods: {
    ...mapActions('companiesModule', [
      'imageProfileUpload'
    ]),
    async initialize () {
      if (this.$route.params.id) {
        this.zbBarId = this.$route.params.id
        // console.log(this.$route.params.id)
        try {
          this.barData = await _barsService.show(this.$route.params.id)
          console.log(this.barData.bar.images)
          if (this.barData.bar.images) {
            console.log('bardata images')
            if (this.barData.bar.images.carousel) {
              console.log('bardata images carousel')
              this.carouselImg = this.barData.bar.images.carousel
            }
          }
          // if (this.barData.bar.images === null) {
          //   this.barData.bar.images = {}
          // }
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        // this.$route.push('/')
        this.$router.push({ name: 'companies' })
      }
    },
    async submitEditImages () {
      this.loading = true
      if (this.imageBanner && this.imageBanner[0]) {
        const newImage = new FormData()
        newImage.append('banner', this.imageBanner[0])
        // console.log(newImage, this.imageProfile)
        try {
          await _barsService.postImage(this.zbBarId, newImage)
          this.$notify({
            type: 'success',
            text: 'Imagen de banner cambiada correctamente'
          })
          this.loading = false
        } catch (error) {
          // console.log(error)
          this.$notify({
            type: 'error',
            text: error.msg
          })
        }
      }
      if (this.imageProfile && this.imageProfile[0]) {
        const newImage = new FormData()
        newImage.append('profile', this.imageProfile[0])
        // console.log(newImage, this.imageProfile, this.zbBarId)
        try {
          await _barsService.postImage(this.zbBarId, newImage)
          this.$notify({
            type: 'success',
            text: 'Imagen de perfil cambiada correctamente'
          })
          this.loading = false
        } catch (error) {
          // console.log(error)
          this.$notify({
            type: 'error',
            text: error.msg
          })
        }
      }
    },
    handleProfileUpload (e) {
      this.imageProfile = e.target.files
      this.previewProfile(e)
    },
    handleBannerUpload (e) {
      this.imageBanner = e.target.files
      this.previewBanner(e)
    },
    previewProfile (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.barData.bar.images = { profile: null }
          // if (!this.barData.bar.images) {
          //   this.barData.bar.images = { profile: null }
          // }
          this.barData.bar.images.profile = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    previewBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.barData.bar.images = { banner: null }
          // if (!this.barData.bar.images) {
          //   this.barData.bar.images = { banner: null }
          // }
          this.barData.bar.images.banner = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    imgDialog (item) {
      console.log(item)
      this.dialog = true
      this.submitAction = 'edit'
      this.currentItem = {
        image: item
      }
    },
    newImgDialog () {
      console.log('new img')
      this.dialog = true
      this.submitAction = 'create'
    },
    closeImgModal () {
      console.log('action on close modal')
      this.dialog = false
      this.initialize()
    },
    async move (m, i, item) {
      // console.log(m, i, item)
      this.carouselImg.splice(i, 1)
      if (m === 'l') {
        // console.log(i--)
        this.carouselImg.splice(i - 1, 0, item)
      }
      if (m === 'r') {
        // console.log(i++)
        this.carouselImg.splice(i + 1, 0, item)
      }
      console.log(this.zbBarId, this.carouselImg)
      try {
        var carrResp = await _barsService.carOrderImg(this.zbBarId, { images: this.carouselImg })
        console.log(carrResp)
        this.$notify({
          type: 'success',
          text: 'Imagen de carrusel cambiada correctamente'
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: `Ocurrió un error al cambiar el orden.`
        })
        this.initialize()
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
